import React from 'react';
import { Helmet } from 'react-helmet-async';
import constants from '../constants';
import Layout from '../components/Layout';
import ShrineList from '../components/ShrineList';
import BlogList from '../components/BlogList';
import SubTitle, { SubTitleTypes } from '../components/SubTitle';
import Title from '../components/Title';

export default ({ pageContext }) => {
  return (
    <Layout>
      <Helmet titleTemplate="%s | ヒナタビ">
        <title>{pageContext.tag}の神社と記事</title>
        <meta
          name="description"
          content={`神社／パワースポット検索サイト・ヒナタビで掲載している${pageContext.tag}の神社と記事一覧です`}
        />
      </Helmet>
      <Title>{pageContext.tag}</Title>

      <SubTitle type={SubTitleTypes.shrine} />
      <ShrineList
        type={constants.articleTypes.blessing}
        value={pageContext.blessing}
      />

      <SubTitle type={SubTitleTypes.article} />
      <BlogList type={constants.articleTypes.tag} value={pageContext.tag} />
    </Layout>
  );
};
